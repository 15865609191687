import React from 'react';
import cn from 'classnames';
import { Lang } from '@rcdev/api-bff-parking';
import { Link as UILink } from '@rcdev/ui-link';
import { Text } from '@rcdev/ui-text';

import { ContentWrapper } from '../ContentWrapper';
import { Logo } from '../Icons/Logo';

import styles from './footer.pcss';

interface IFooterProps {
  lang: Lang;
  domain?: string;
  homeLink: { en: string; ru: string };
  queryLangSwitch?: boolean;
}

const menuRu = [
  [
    {
      title: 'Регистрация доменов',
      url: '/catalog/domains/',
    },
    {
      title: 'Хостинг для сайтов',
      url: '/catalog/hosting/',
    },
    {
      title: 'Виртуальный хостинг',
      url: '/catalog/hosting/shared/',
    },
  ],
  [
    {
      title: 'VPS/VDS хостинг',
      url: '/catalog/hosting/vds-vps/',
    },
    {
      title: 'Аренда сервера',
      url: '/catalog/hosting/dedicated/',
    },
    {
      title: 'SSL-сертификаты',
      url: '/catalog/ssl/',
    },
  ],
];

// Обратить внимание что ссылки в menuEn НЕ СОДЕРЖАТ указания языка
// Язык в них подставляется в зависимости от языка текущей страницы expired
const menuEn = [
  [
    {
      title: 'Domain Registration',
      url: '/catalog/domains/',
    },
    {
      title: 'Hosting',
      url: '/catalog/hosting/',
    },
    {
      title: 'Shared Hosting',
      url: '/catalog/hosting/shared/',
    },
  ],
  [
    {
      title: 'VDS/VPS virtual servers',
      url: '/catalog/hosting/vds-vps/',
    },
    {
      title: 'Dedicated servers',
      url: '/catalog/hosting/dedicated/',
    },
    {
      title: 'SSL certificates',
      url: '/catalog/ssl/',
    },
  ],
];

const getMenuByLang = (lang: Lang) => {
  if (lang === Lang.En) {
    return menuEn;
  }

  return menuRu;
};

// eslint-disable-next-line arrow-body-style
const concatUrl = (homeUrl: string, path: string): string => {
  if (path.startsWith('http')) {
    return path;
  }

  return `${homeUrl.endsWith('/') ? homeUrl.slice(0, -1) : homeUrl}${path.startsWith('/') ? path : `/${path}`}`;
};

const getLangSwitchUrl = (props: IFooterProps) => {
  const { lang, domain, queryLangSwitch } = props;

  if (queryLangSwitch) {
    return `/?lang=${lang === Lang.Ru ? 'en' : 'ru'}&domain=${domain}`;
  }

  return lang === Lang.Ru ? '/en/' : '/';
};

export const Footer = (props: IFooterProps) => {
  const { lang, homeLink } = props;

  return (
    <div className={styles.footer}>
      <ContentWrapper>
        <div className={styles.footerContentWrapper}>
          <div className={styles.footerContent}>
            <div className={styles.footerLogoBlock}>
              <UILink
                preset="regular"
                href={homeLink[`${lang}`]}
                className={styles.footerLogo}
              >
                <Logo />
              </UILink>
              <UILink
                preset="regular"
                href={getLangSwitchUrl(props)}
                className={styles.footerLang}
              >
                <Text preset="small">{`${lang === Lang.Ru ? 'Eng' : 'Ru'}`}</Text>
              </UILink>
            </div>

            <span className={cn(styles.footerCopyright, styles.footerCopyright_desktop)}>
              <Text color="secondary">{`© АО «РСИЦ» (RU-CENTER), ${new Date().getFullYear()}`}</Text>
            </span>
          </div>

          <div className={styles.footerMenu}>
            {/* eslint-disable react/no-array-index-key */}
            {getMenuByLang(lang).map((column, index) => (
              <div className={styles.footerMenuColumn} key={`menu-${lang}-${index}`}>
                {column.map((item) => (
                  <UILink
                    preset="regular"
                    className={styles.footerMenuItem}
                    href={concatUrl(homeLink[`${lang}`], item.url)}
                    key={item.title}
                  >
                    <Text preset="small">{item.title}</Text>
                  </UILink>
                ))}
              </div>
            ))}
          </div>

          <span className={cn(styles.footerCopyright, styles.footerCopyright_mobile)}>
            <Text color="secondary">{`© АО «РСИЦ» (RU-CENTER), ${new Date().getFullYear()}`}</Text>
          </span>
        </div>
      </ContentWrapper>
    </div>
  );
};
