import React from 'react';
import { Text } from '@rcdev/ui-text';

import { Link } from '../../components/Link';
import { IImageProps, ILinkProps } from '../types';

import styles from './expiredMainBlock.pcss';

interface IContentBlockExpiredMainBlockProps {
  title: string;
  description: string;
  hint: string;
  image: IImageProps;
  link?: ILinkProps;
}

export const ExpiredMainBlock = (props: IContentBlockExpiredMainBlockProps) => {
  const { title, description, link, hint, image } = props;

  return (
    <div className={styles['expiredMainBlock-wrapper']}>
      <div className={styles.expiredMainBlock}>
        <div className={styles['expiredMainBlock-content']}>
          <div className={styles['expiredMainBlock-title']}>
            <Text preset="h1" wrap="break-word">{title}</Text>
          </div>
          {description && <Text html>{description}</Text>}
          {link &&
            <div className={styles['expiredMainBlock-btn']}>
              <Link {...link} />
            </div>}
          {hint &&
            <div className={styles['expiredMainBlock-hint']}>
              <Text color="secondary" preset="small" html>{hint}</Text>
            </div>}
        </div>
        {image &&
          <div className={styles['expiredMainBlock-image']}>
            <img src={image.url} alt={image.alt} />
          </div>}
      </div>
    </div>
  );
};
