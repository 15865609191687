import React from 'react';
import { Provider } from 'react-redux';
import { Lang } from '@rcdev/api-bff-parking';

import { BasePage, createStore, IPageContext, makePageHydrate, sanitizeConfig, TSanitizedConfig } from '../../core';
import { getBffExpiredApi } from '../api/expired/expiredApiClient';
import { PageWrapper } from '../components/PageWrapper';
import { renderBlocks } from '../components/RenderBlocks';
import { ExpiredContentContainer } from '../containers/ExpiredContentContainer';
import { FooterContainer } from '../containers/FooterContainer';
import { HeaderContainer } from '../containers/HeaderContainer';
import { HTMLHeader } from '../containers/HTMLHeader';
import { RenderBlocksContext } from '../contexts/RenderBlocksContext';
import { IAppState, IExtraArgument, mainReducer } from '../reducers';
import { getExpiredContent, setData } from '../reducers/InitialParameters/initial.toolkit';

import '@rcdev/ui-text/dist/index.css';
import '@rcdev/ui-button/dist/index.css';
import '@rcdev/ui-link/dist/index.css';

export type TExpiredPageProps = {
  serverState: IAppState;
  serverConfig: TSanitizedConfig;
};

export default class ExpiredPages extends BasePage<TExpiredPageProps> {
  public static displayName = 'ExpiredPages';

  /**
   * Данный метод гарантирует что все запросы выполненные в нем будут выполнены
   * на сервере, а все что будет возвращено из него будет сериализовано и отправлено
   * на клиент в формате JSON
   * @param context
   */
  public static async getInitialProps(context: IPageContext): Promise<TExpiredPageProps> {
    const serverConfig = sanitizeConfig(context.serverConfiguration);
    const configuration = { basePath: serverConfig.api.expired };
    // Можно создать redux стор на сервере, для запуска thunk actions
    // Только тут можно отправлять api запросы сервер<->сервер, запросы отправленные после вызова
    // метода render не будут сериализованны или доступны.
    const store = createStore<IAppState, IExtraArgument>({
      reducer: mainReducer,
      extraArgument: {
        serverConfig,
        expiredApi: getBffExpiredApi(configuration, context.req),
      },
    });

    store.dispatch(setData({
      lang: context.req.query?.lang || Lang.Ru,
      domain: context.req.query?.domain,
    }));

    await store.dispatch(getExpiredContent());

    return {
      serverConfig: sanitizeConfig(context.serverConfiguration, true),
      serverState: store.getState(),
    };
  }

  public render() {
    const { serverConfig, serverState: state } = this.props;

    const configuration = { basePath: serverConfig.api.expired };

    // Создание изоморфного стора (он будет и на клиенте и на сервере)
    const store = createStore<IAppState, IExtraArgument>({
      state,
      reducer: mainReducer,
      extraArgument: {
        serverConfig,
        expiredApi: getBffExpiredApi(configuration),
      },
    });

    return (
      <Provider store={store}>
        <HTMLHeader isDevelopment={process.env.NODE_ENV === 'development'} />
        <PageWrapper>
          <HeaderContainer homeLink={serverConfig.homeLink} />
          <RenderBlocksContext.Provider value={renderBlocks}>
            <ExpiredContentContainer />
          </RenderBlocksContext.Provider>
          <FooterContainer
            homeLink={serverConfig.homeLink}
            queryLangSwitch={serverConfig.queryLangSwitch || false}
          />
        </PageWrapper>
      </Provider>
    );
  }
}

if (process.env.NODE_ENV === 'development') {
  // тут это нужно для магии HMR в Dev режиме
  // eslint-disable-next-line global-require, import/no-extraneous-dependencies,@typescript-eslint/no-require-imports
  const { hot } = require('react-hot-loader/root');
  makePageHydrate(hot(ExpiredPages));
} else {
  makePageHydrate(ExpiredPages);
}
