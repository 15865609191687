import React from 'react';
import { Lang } from '@rcdev/api-bff-parking';
import { Link as UILink } from '@rcdev/ui-link';

import { ContentWrapper } from '../ContentWrapper';
import { Logo } from '../Icons/Logo';

import styles from './header.pcss';

interface IHeaderProps {
  lang: Lang;
  homeLink: { ru: string; en: string };
}

export const Header = (props: IHeaderProps) => {
  const { lang, homeLink } = props;

  return (
    <div className={styles.header}>
      <ContentWrapper>
        <UILink
          preset="regular"
          href={homeLink[`${lang}`]}
          className={styles.logo}
        >
          <Logo />
        </UILink>
      </ContentWrapper>
    </div>
  );
};
