import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import type { PageMeta, PageOpenGraph } from '@rcdev/api-bff-parking';

import { getBaseFonts, getFavIcons } from '../../../core/pages/sharedStatic';
import { IAppState } from '../../reducers';

type THTMLHeader = { isDevelopment: boolean };

/**
  Подключение стилей, meta и шрифтов
*/
export function HTMLHeader(props: THTMLHeader) {
  const meta = useSelector<IAppState, PageMeta | null>((state) => state.initialParameters.page?.meta || null);
  const openGraph = useSelector<IAppState, PageOpenGraph | null>(
    (state) => state.initialParameters.page?.openGraph || null,
  );
  const { isDevelopment } = props;

  return (
    <Helmet>
      {meta?.title ? (<title>{meta.title}</title>) : null}
      {meta?.description ? (<meta name="description" content={meta.description} />) : null}
      {meta?.hiddenForSE ? (<meta name="robots" content="noindex" />) : null}

      {openGraph?.title ? (<meta property="og:title" content={openGraph.title} />) : null}
      {openGraph?.description ? (<meta name="og:description" content={openGraph.description} />) : null}
      {isDevelopment ? null : getFavIcons()}
      <style type="text/css">
        {
          `
          html {
            height: 100%;
          }
          body {
            height: 100%;
            margin: 0;
            background-color: #fff;
          }
          #frontend-expired-pages {
            height: 100%;
          }
          `
        }
      </style>
      <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      {/* именно вызов функции а не как компонент */}
      {getBaseFonts({ isGoogleStatic: isDevelopment })}
    </Helmet>
  );
}
