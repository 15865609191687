import * as React from 'react';

export function Logo() {
  return (
    <svg width="100" height="42" viewBox="0 0 100 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path d="M39.2312 20.9475C38.5291 20.9475 37.8711 20.8599 37.2572 20.6853C36.643 20.5101 36.1075 20.2386 35.6492 19.8703C35.2028 19.5083 34.8549 19.0539 34.6046 18.5081C34.354 17.9616 34.2285 17.3299 34.2285 16.6124C34.2285 15.6855 34.4043 14.8246 34.7557 14.0302C35.1069 13.2354 35.6004 12.5411 36.235 11.9456C36.8587 11.3621 37.6191 10.9003 38.5167 10.5599C39.4146 10.219 40.3862 10.0491 41.43 10.0491C42.0596 10.0491 42.6839 10.1195 43.304 10.2608C43.9238 10.4019 44.4992 10.6071 45.0306 10.8765L44.3344 13.7005H43.8667C43.5072 13.2651 43.0992 12.9417 42.6442 12.73C42.1892 12.5185 41.6776 12.4122 41.1093 12.4122C40.0711 12.4122 39.2371 12.7831 38.6084 13.5229C37.9788 14.2621 37.6643 15.1854 37.6643 16.2902C37.6643 17.0582 37.8584 17.6323 38.2463 18.0124C38.6344 18.3932 39.2371 18.5838 40.056 18.5838C40.6907 18.5838 41.2696 18.4531 41.7921 18.193C42.3143 17.9322 42.7617 17.643 43.1347 17.324H43.6016L42.9054 20.1468C42.2457 20.4352 41.6667 20.6404 41.1694 20.7634C40.6714 20.8857 40.0247 20.9475 39.2312 20.9475Z" fill="#14295E" />
      {/* eslint-disable-next-line max-len */}
      <path d="M53.1509 14.1788C53.1695 14.0678 53.1828 13.9659 53.1923 13.8744C53.2017 13.7815 53.2065 13.6867 53.2065 13.5876C53.2065 13.1011 53.0556 12.7287 52.7556 12.47C52.455 12.211 52.0227 12.0815 51.4591 12.0815C50.7787 12.0815 50.2065 12.2666 49.7435 12.636C49.2798 13.0064 48.9298 13.5204 48.6905 14.1788H53.1509ZM50.6269 20.948C48.8727 20.948 47.5192 20.5584 46.5648 19.7785C45.6118 18.9989 45.1343 17.922 45.1343 16.5476C45.1343 15.6088 45.2902 14.7467 45.602 13.9614C45.9136 13.1759 46.363 12.486 46.9503 11.8903C47.5121 11.3132 48.2153 10.8571 49.0582 10.5185C49.9023 10.1814 50.829 10.0122 51.8381 10.0122C53.371 10.0122 54.5287 10.3243 55.3083 10.9474C56.0872 11.5707 56.4772 12.4905 56.4772 13.706C56.4772 14.1239 56.4352 14.5317 56.3532 14.9311C56.2704 15.3303 56.1618 15.7267 56.0275 16.119H48.3689V16.3311C48.3689 17.0477 48.6204 17.6093 49.121 18.0138C49.6236 18.4189 50.3929 18.6208 51.4278 18.6208C52.1503 18.6208 52.834 18.4985 53.4804 18.2532C54.1263 18.0082 54.6887 17.7343 55.1659 17.4347H55.5337L54.9183 20.0924C54.2769 20.3745 53.6017 20.5878 52.8923 20.7312C52.184 20.8758 51.4287 20.948 50.6269 20.948Z" fill="#14295E" />
      {/* eslint-disable-next-line max-len */}
      <path d="M68.7965 10.7206C68.2832 10.2734 67.5642 10.0494 66.6405 10.0494C65.9678 10.0494 65.3521 10.1757 64.792 10.4257C64.2317 10.6779 63.6136 11.0274 62.9346 11.475L63.2065 10.3343L58.7385 10.3351L58.2337 12.5144H59.3852L57.5098 20.6619H60.8287L62.5044 13.3418C62.9323 13.1149 63.3172 12.9453 63.6601 12.831C64.0021 12.7178 64.305 12.6611 64.5681 12.6611C65.1059 12.6611 65.4755 12.7529 65.6772 12.9364C65.879 13.1214 65.9802 13.4122 65.9802 13.8107C65.9802 14.0439 65.9556 14.2963 65.9062 14.5651C65.858 14.8347 65.7908 15.1599 65.7059 15.5395L64.5355 20.6619H67.8761L69.4119 13.9393C69.466 13.7071 69.5068 13.4832 69.5305 13.2679C69.5547 13.0534 69.5666 12.8539 69.5666 12.67C69.5666 11.818 69.3098 11.1679 68.7965 10.7206Z" fill="#14295E" />
      {/* eslint-disable-next-line max-len */}
      <path d="M86.2181 14.1788C86.2359 14.0678 86.2498 13.9659 86.259 13.8744C86.2684 13.7815 86.2735 13.6867 86.2735 13.5876C86.2735 13.1011 86.1229 12.7287 85.822 12.47C85.522 12.211 85.0897 12.0815 84.5255 12.0815C83.8448 12.0815 83.2732 12.2666 82.8105 12.636C82.3474 13.0064 81.9971 13.5204 81.7569 14.1788H86.2181ZM83.6942 20.948C81.9394 20.948 80.5859 20.5584 79.632 19.7785C78.6788 18.9989 78.2007 17.922 78.2007 16.5476C78.2007 15.6088 78.3569 14.7467 78.6684 13.9614C78.9803 13.1759 79.4297 12.486 80.017 11.8903C80.5791 11.3132 81.2818 10.8571 82.1255 10.5185C82.9693 10.1814 83.8951 10.0122 84.9042 10.0122C86.4383 10.0122 87.5954 10.3243 88.375 10.9474C89.1545 11.5707 89.5439 12.4905 89.5439 13.706C89.5439 14.1239 89.5022 14.5317 89.4196 14.9311C89.3377 15.3303 89.2285 15.7267 89.0939 16.119H81.4356V16.3311C81.4356 17.0477 81.6865 17.6093 82.1886 18.0138C82.6909 18.4189 83.4593 18.6208 84.4945 18.6208C85.217 18.6208 85.9013 18.4985 86.5468 18.2532C87.1936 18.0082 87.7557 17.7343 88.2329 17.4347H88.6004L87.9856 20.0924C87.3436 20.3745 86.6681 20.5878 85.9596 20.7312C85.2501 20.8758 84.4954 20.948 83.6942 20.948Z" fill="#14295E" />
      {/* eslint-disable-next-line max-len */}
      <path d="M99.2681 13.4888H98.9758C98.8288 13.4395 98.622 13.403 98.3533 13.3789C98.0853 13.3543 97.8202 13.3412 97.5578 13.3412C97.124 13.3412 96.7066 13.3742 96.3042 13.438C95.9013 13.5028 95.4868 13.6046 95.0607 13.7463L93.4622 20.6619H90.1421L92.5276 10.3339H95.8489L95.4986 11.8518C96.1202 11.3921 96.7406 11.0182 97.3604 10.7295C97.9791 10.4418 98.6033 10.2974 99.2315 10.2974C99.3474 10.2974 99.4773 10.3007 99.6202 10.3066C99.764 10.3129 99.8906 10.3221 99.9998 10.3339L99.2681 13.4888Z" fill="#14295E" />
      {/* eslint-disable-next-line max-len */}
      <path d="M76.5138 10.3344L77.1899 7.38243H73.8798L73.2017 10.3344H71.8428L71.3381 12.5143H72.7301L70.8579 20.6624H74.168C74.1881 20.5789 74.205 20.4949 74.2248 20.4111L76.0396 12.5143H78.2428L78.7443 10.3344H76.5138Z" fill="#14295E" />
      {/* eslint-disable-next-line max-len */}
      <path d="M11.7508 12.4608C11.4899 12.2898 11.1042 12.2045 10.5951 12.2045H9.2993L8.66681 14.9427H9.9554C10.6505 14.9427 11.1891 14.8008 11.5705 14.5163C11.9524 14.2315 12.1431 13.8362 12.1431 13.3319C12.1431 12.9221 12.0131 12.6313 11.7508 12.4608ZM24.9706 16.7962C24.6395 18.2069 24.0778 19.23 23.2867 19.8664C22.4953 20.5029 21.3494 20.8215 19.8493 20.8215C18.5122 20.8215 17.5007 20.5807 16.8142 20.0993C16.1277 19.6176 15.7851 18.8891 15.7851 17.9117C15.7851 17.7115 15.8003 17.5081 15.8321 17.3026C15.8639 17.0971 15.898 16.91 15.935 16.7413L17.3935 10.4298H20.0342L18.5801 16.7478C18.5474 16.8758 18.5218 17.0166 18.501 17.1692C18.4796 17.3216 18.47 17.449 18.47 17.5491C18.47 17.9779 18.5986 18.3037 18.8564 18.5247C19.1129 18.7459 19.539 18.857 20.1346 18.857C20.7132 18.857 21.1801 18.6966 21.5364 18.3774C21.8932 18.0581 22.1495 17.561 22.3046 16.8853L23.7972 10.4298H26.4429L24.9706 16.7962ZM14.551 20.6232H11.6275L9.71537 16.8853H8.24415L7.38202 20.6232H4.764L7.10776 10.4298H11.071C12.313 10.4298 13.272 10.6403 13.9504 11.0624C14.6279 11.4847 14.9663 12.13 14.9663 12.9963C14.9663 13.8139 14.7186 14.5002 14.2228 15.0568C13.7274 15.6139 13.055 16.0339 12.2038 16.3169L14.551 20.6232ZM15.7148 0C7.0353 0 0 7.06316 0 15.7755C0 24.4884 7.0353 31.5513 15.7148 31.5513C24.3941 31.5513 31.4311 24.4884 31.4311 15.7755C31.4311 7.06316 24.3941 0 15.7148 0Z" fill="#14295E" />
      {/* eslint-disable-next-line max-len */}
      <path d="M67.6909 29.0176C79.3004 28.8359 89.1729 30.3268 99.1019 32.6982C90.5281 28.8251 81.2373 26.3193 70.6522 25.6576C68.319 25.5117 65.9229 25.4555 63.4578 25.4939C43.5033 25.8058 25.779 32.2138 14.1929 42C28.0245 34.4046 46.8631 29.342 67.6909 29.0176Z" fill="#EF3F23" />
    </svg>
  );
}
