import { createElement } from 'react';

export const getBaseFonts = (props: { isGoogleStatic: boolean }) => (props.isGoogleStatic ? (
  createElement('link', {
    href: 'https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap&subset=cyrillic-ext',
    rel: 'stylesheet',
  })
) : (
/* eslint-disable max-len */
  createElement('style', { type: 'text/css' }, `
        @font-face{font-family:Noto Sans;font-style:normal;font-weight:400;src:local("Noto Sans"),local("NotoSans"),url("/static/assets/fonts/o-0IIpQlx3QUlC5A4PNr6DRASf6M7VBj.woff2") format("woff2");unicode-range:u+0460-052f,u+1c80-1c88,u+20b4,u+2de0-2dff,u+a640-a69f,u+fe2e-fe2f}
        @font-face{font-family:Noto Sans;font-style:normal;font-weight:400;src:local("Noto Sans"),local("NotoSans"),url("/static/assets/fonts/o-0IIpQlx3QUlC5A4PNr4TRASf6M7VBj.woff2") format("woff2");unicode-range:u+0400-045f,u+0490-0491,u+04b0-04b1,u+2116}
        @font-face{font-family:Noto Sans;font-style:normal;font-weight:400;src:local("Noto Sans"),local("NotoSans"),url("/static/assets/fonts/o-0IIpQlx3QUlC5A4PNr6zRASf6M7VBj.woff2") format("woff2");unicode-range:u+0100-024f,u+0259,u+1e??,u+2020,u+20a0-20ab,u+20ad-20cf,u+2113,u+2c60-2c7f,u+a720-a7ff}
        @font-face{font-family:Noto Sans;font-style:normal;font-weight:400;src:local("Noto Sans"),local("NotoSans"),url("/static/assets/fonts/o-0IIpQlx3QUlC5A4PNr5TRASf6M7Q.woff2") format("woff2");unicode-range:u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd}
        @font-face{font-family:Noto Sans;font-style:normal;font-weight:700;src:local("Noto Sans Bold"),local("NotoSans-Bold"),url("/static/assets/fonts/o-0NIpQlx3QUlC5A4PNjXhFVadyBx2pqPIif.woff2") format("woff2");unicode-range:u+0460-052f,u+1c80-1c88,u+20b4,u+2de0-2dff,u+a640-a69f,u+fe2e-fe2f}
        @font-face{font-family:Noto Sans;font-style:normal;font-weight:700;src:local("Noto Sans Bold"),local("NotoSans-Bold"),url("/static/assets/fonts/o-0NIpQlx3QUlC5A4PNjXhFVYNyBx2pqPIif.woff2") format("woff2");unicode-range:u+0400-045f,u+0490-0491,u+04b0-04b1,u+2116}
        @font-face{font-family:Noto Sans;font-style:normal;font-weight:700;src:local("Noto Sans Bold"),local("NotoSans-Bold"),url("/static/assets/fonts/o-0NIpQlx3QUlC5A4PNjXhFVatyBx2pqPIif.woff2") format("woff2");unicode-range:u+0100-024f,u+0259,u+1e??,u+2020,u+20a0-20ab,u+20ad-20cf,u+2113,u+2c60-2c7f,u+a720-a7ff}
        @font-face{font-family:Noto Sans;font-style:normal;font-weight:700;src:local("Noto Sans Bold"),local("NotoSans-Bold"),url("/static/assets/fonts/o-0NIpQlx3QUlC5A4PNjXhFVZNyBx2pqPA.woff2") format("woff2");unicode-range:u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd}
        `)
));
/* eslint-enable max-len */

const favicons = [
  {
    tagName: 'meta',
    props: {
      name: 'msapplication-square70x70logo',
      content: 'favicon_70x70.png',
    },
  },
  {
    tagName: 'meta',
    props: {
      name: 'msapplication-square150x150logo',
      content: 'favicon_150x150.png',
    },
  },
  {
    tagName: 'meta',
    props: {
      name: 'msapplication-wide310x150logo',
      content: 'favicon_310x150.png',
    },
  },
  {
    tagName: 'meta',
    props: {
      name: 'msapplication-square310x310logo',
      content: 'favicon_310x310.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'icon',
      type: 'image/png',
      href: 'favicon.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      href: 'favicon_192x192.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      href: 'favicon_192x192.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '152x152',
      href: 'favicon_152x152.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '180x180',
      href: 'favicon_180x180.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '167x167',
      href: 'favicon_167x167.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: 'favicon_192x192.png',
    },
  },
];

const getFaviconStaticPath = (favicon: string) => `/static/${favicon}`;

export const getFavIcons = () => favicons.map((obj, index) => createElement(obj.tagName, obj.tagName === 'meta' ? {
  ...obj.props,
  ...(obj.props.content ? { content: getFaviconStaticPath(obj.props.content) } : {}),
  key: `favicon-${index}`,
} : {
  ...obj.props,
  ...(obj.props.href ? { href: getFaviconStaticPath(obj.props.href) } : {}),
  key: `favicon-${index}`,
}));
