import React from 'react';

import { PageBlocksContext } from '../../contexts/PageBlocksContext';
import { useRenderBlocksContext } from '../../contexts/RenderBlocksContext';
import { TUpdatedContentBlock } from '../../utils/convertBlocksStructure';

import styles from './linesContentView.pcss';

export const LinesContentView = (props: TUpdatedContentBlock) => {
  const { children } = props;
  const renderBlocks = useRenderBlocksContext();

  if (children && children.length > 0) {
    return (
      <div className={styles.linesContentView}>
        {/* eslint-disable react/no-array-index-key */}
        {children.map((childBlock, index) => (
          <PageBlocksContext.Provider key={index} value={childBlock.length === 1 ? 1 : 2}>
            <div className={styles[`linesContentView-${childBlock.length}-column`]}>
              {childBlock.map((el) => renderBlocks(el))}
            </div>
          </PageBlocksContext.Provider>
        ))}
      </div>
    );
  }

  return null;
};
