import React from 'react';
import cn from 'classnames';
import { Link as ILink, LinkView } from '@rcdev/api-bff-parking';
import { Button } from '@rcdev/ui-button';
import { Link as UILink } from '@rcdev/ui-link';

import styles from './link.pcss';

const getButtonStyles = (view: LinkView) => {
  switch (view) {
    case 'buttonOutlined':
      return 'outline';
    case 'buttonFilled':
    default:
      return 'flat';
  }
};

export const Link = (link: ILink) => {
  const { view, title, url, target } = link;

  if (view === 'default') {
    return <UILink href={url} target={target}>{title}</UILink>;
  }

  return (
    <Button variant="tall" view={getButtonStyles(view)}>
      <UILink
        className={cn(styles.linkInBtn, styles[getButtonStyles(view)])}
        href={link.url}
        target={target}
      >
        {link.title}
      </UILink>
    </Button>
  );
};
